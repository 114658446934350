import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Framework from '../components/framework';
import Link from '../components/framework/link';
import Loader from '../components/framework/loader';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';

import { FilePdfFill } from 'react-bootstrap-icons';

const ResultPage = ({ location, search }) => {
	const { id, test } = search;
	const [result, setResult] = useState();

	const url_pdf = `https://europe-west1-testmottagningen.cloudfunctions.net/result-pdf?id=${id}${
		test ? '&test=true' : ''
	}`;

	useEffect(async () => {
		if (id) {
			const options = {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			};
			const url = `https://europe-west1-testmottagningen.cloudfunctions.net/result-data?id=${id}`;
			try {
				const response = await fetch(url, options);
				const result = await response.json();
				setResult(result);
			} catch (error) {
				setResult('error');
			}
		}
	}, [id]);

	const getAnalyzeColor = (data) => {
		const colors = {
			inconclusive: 'secondary',
			negative: 'success',
			positive: 'danger',
		};
		let out = '';

		if (
			data.analyze_result != 'negative' &&
			data.analyze_result != 'positive'
		) {
			return colors.inconclusive;
		}

		if (data.type == 'antigen' || data.type == 'pcr') {
			out = colors[data.analyze_result];
		} else if (data.type == 'antibody') {
			out =
				data.analyze_result == 'positive' ? colors.negative : colors.positive;
		}

		if (!out) out = colors.inconclusive;
		return out;
	};

	return (
		<Framework location={location}>
			<Seo title="Test result" />
			<div className="container my-5 pt-lg-5">
				<h1 className="h3">Test result</h1>
				<div className="row">
					<div className="col-lg-8">
						{!id && <div></div>}
						{!result && id && (
							<div>
								<Loader text="Loading result, please wait" />
							</div>
						)}

						{result && result.status === 'error' && (
							<div className="alert alert-danger my-3 small" role="alert">
								{result.data && result.data.codes && (
									<>
										<strong>Unable to find test result</strong>
										<p>
											Test is unavailable because it is older than 7 days. If
											you still have problem contact us{' '}
											<Link to="/contact">here</Link>.
										</p>
									</>
								)}
								{result.data && !result.data.codes && (
									<>
										<strong>Opps</strong>
										<p>
											We where unable to get your result data, please try again.
											If you still have problem contact us{' '}
											<Link to="/contact">here</Link>.
										</p>
									</>
								)}
							</div>
						)}

						{result && result.data && result.data.firstname && (
							<div>
								{(result.data.type == 'antigen' ||
									result.data.type == 'pcr') && (
									<p className="mb-3">
										Validity of the {result.data.type}-test certificate is 96
										hours.
									</p>
								)}
								{result.data.type != 'antigen' && result.data.type != 'pcr' && (
									<p className="mb-3">Result of the {result.data.type}-test.</p>
								)}

								<ol className="list-group bg-cream">
									<li className="list-group-item d-flex justify-content-between align-items-start px-1">
										<div className="ms-2 me-auto">
											<div className="fw-bold">Name:</div>
											{result.data.firstname} {result.data.lastname}
										</div>
									</li>
									<li className="list-group-item d-flex justify-content-between align-items-start px-1">
										<div className="ms-2 me-auto">
											<div className="fw-bold">Date of Birth:</div>
											{result.data.date_of_birth}
										</div>
									</li>
									<li className="list-group-item d-flex justify-content-between align-items-start px-1">
										<div className="ms-2 me-auto">
											<div className="fw-bold">Citizenship:</div>
											{result.data.citizenship}
										</div>
									</li>
									{result.data.address && (
										<li className="list-group-item d-flex justify-content-between align-items-start px-1">
											<div className="ms-2 me-auto">
												<div className="fw-bold">Address:</div>
												{result.data.address}
											</div>
										</li>
									)}
									{result.data.passport_number && (
										<li className="list-group-item d-flex justify-content-between align-items-start px-1">
											<div className="ms-2 me-auto">
												<div className="fw-bold">Passport Number:</div>
												{result.data.passport_number}
											</div>
										</li>
									)}
									{result.data.personal_number && (
										<li className="list-group-item d-flex justify-content-between align-items-start px-1">
											<div className="ms-2 me-auto">
												<div className="fw-bold">Personal Identity Number:</div>
												{result.data.personal_number}
											</div>
										</li>
									)}
									<li className="list-group-item d-flex justify-content-between align-items-start px-1">
										<div className="ms-2 me-auto">
											<div className="fw-bold">Date of test:</div>
											{result.data.tested_at}
										</div>
									</li>
									<li
										className={`list-group-item list-group-item-${getAnalyzeColor(
											result.data
										)} d-flex justify-content-between align-items-start px-1`}
									>
										<div className="ms-2 me-auto">
											<div className="fw-bold">Result:</div>
											{(result.data.type == 'antigen' ||
												result.data.type == 'pcr') && (
												<p>
													Has been tested&#160;
													<strong>
														{result.data.analyze_result.toUpperCase()}
													</strong>
													&#160;for SARS-CoV-2 (Covid-19).&#160;
													{result.data.certification}
												</p>
											)}
										</div>
									</li>
								</ol>

								{result.data.analyze_result === 'positive' &&
									(result.data.type == 'antigen' ||
										result.data.type == 'pcr') && (
										<Link
											className="btn btn-danger text-light px-3 rounded-pill me-2 mt-3"
											to="https://www.government.nl/topics/coronavirus-covid-19/tackling-new-coronavirus-in-the-netherlands/self-quarantine/self-quarantaining-or-self-isolating-due-to-coronavirus"
										>
											Download Covid-19 information
											<FilePdfFill className="ms-1" />
										</Link>
									)}

								{result.data.certificates.testmottagningen && (
									<Link
										className="btn btn-primary text-light px-3 rounded-pill me-2 mt-3"
										to={`${url_pdf}`}
									>
										Download result
										<FilePdfFill className="ms-1" />
									</Link>
								)}
								{result.data.certificates.covidbevis && (
									<Link
										className="btn btn-primary text-light px-3 rounded-pill mt-3"
										to={`${url_pdf}&type=covidbevis`}
									>
										Download EU covid proof
										<FilePdfFill className="ms-1" />
									</Link>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</Framework>
	);
};

ResultPage.propTypes = {
	location: PropTypes.object.isRequired,
	search: PropTypes.object,
};

export default withLocation(ResultPage);

export const query = graphql`
	query {
		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
